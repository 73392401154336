import { Validator } from "breeze-client";
import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";
import { AssetLoan } from "./asset-loan";
import { EnsembleMembership } from "./ensemble-membership";
import { PerformanceMember } from "./performance";

export enum MemberCategory {
    Current,
    Past,
}

interface CategoryMetadata {
    category: MemberCategory;
    label: string;
    singularText: string;
    pluralText: string;
    icon: string;
}
export const MemberCategoryMetadata: Record<MemberCategory, CategoryMetadata> = {
    [MemberCategory.Current]: {
        category: MemberCategory.Current,
        label: "Current Members",
        singularText: "Is a current member",
        pluralText: "Members that are currently active",
        icon: "face",
    },
    [MemberCategory.Past]: {
        category: MemberCategory.Past,
        label: "Past Members",
        singularText: "Is a past member",
        pluralText: "Members that are no longer active",
        icon: "inventory",
    },
};
export const AllMemberCategoryMetadata = Object.values(MemberCategoryMetadata);

export enum FeeClass {
    Full = "Full",
    Special = "Special",
    Concession = "Concession",
}

export class Member extends BreezeEntity {
    public id!: number;
    public firstName!: string;
    public lastName!: string;
    public dateJoined!: Date;
    public dateLeft!: Date | null;

    public details!: MemberDetails | null;

    public assetLoan!: AssetLoan[];
    public ensembleMembership!: EnsembleMembership[];
    public memberInstrument!: MemberInstrument[];
    public performanceMember!: PerformanceMember[];

    public get fullName(): string {
        return this.firstName + " " + this.lastName;
    }

    public get instrumentList(): string {
        if (this.memberInstrument.length === 0) {
            return "None";
        }

        return this.memberInstrument.map((i) => i.instrumentName).join(", ");
    }

    public get category() {
        return !this.dateLeft || this.dateLeft > new Date()
            ? MemberCategory.Current
            : MemberCategory.Past;
    }

    public compare(otherMember: Member) {
        return this.fullName.localeCompare(otherMember.fullName);
    }
}

export class MemberDetails extends BreezeEntity {
    public phoneNo?: string;
    public email!: string | null;
    public address!: string | null;
    public feeClass!: FeeClass;
    public notes!: string | null;

    public member!: Member | null;

    public get hasLogin() {
        // We don't expose this property for access normally
        return !!this.entityAspect.getPropertyValue("userId");
    }
}

export class MemberInstrument extends BreezeEntity {
    public id!: number;
    public memberId!: number;
    public instrumentName!: string;
}

export const MemberBreezeModel: BreezeModel<Member> = {
    typeName: "Member",
    type: Member,
};

export const MemberDetailsBreezeModel: BreezeModel<MemberDetails> = {
    typeName: "MemberDetails",
    type: MemberDetails,
    additionalValidators: {
        // Needed as we this should be required for new users, but we can't enforce it on
        // legacy data (backend throws an error if we add the attribute)
        email: [Validator.required()],
    },
};

export const MemberInstrumentBreezeModel: BreezeModel<MemberInstrument> = {
    typeName: "MemberInstrument",
    type: MemberInstrument,
};
